import { useEffect, useRef } from "react";
import * as THREE from "three";
import { useElementContext } from "../../context/ElementContext";

const TShirtColorController = ({ scene, model }) => {
  const { colors } = useElementContext();
  const previousColors = useRef({ ...colors });

  useEffect(() => {
    // Helper function to interpolate color
    const interpolateColor = (startColor, endColor, factor) => {
      const color1 = new THREE.Color(startColor);
      const color2 = new THREE.Color(endColor);
      return color1.lerp(color2, factor);
    };

    // Function to animate the color transition
    const animateColorTransition = (mesh, targetColor, duration) => {
      let startTime = null;

      const startColor = mesh.material.color.clone();
      const endColor = new THREE.Color(targetColor);

      const animate = (time) => {
        if (!startTime) startTime = time;
        const elapsedTime = time - startTime;
        const factor = Math.min(elapsedTime / duration, 1);

        // Interpolate between the start and end colors
        const newColor = interpolateColor(startColor, endColor, factor);
        mesh.material.color.copy(newColor);

        if (factor < 1) {
          requestAnimationFrame(animate); // Continue animating until transition is complete
        }
      };

      requestAnimationFrame(animate);
    };

    // Traverse the scene and update colors with transitions
    scene.traverse((child) => {
      if (child.isMesh) {
        if (model.color_two_meshes.includes(child.name)) {
          animateColorTransition(child, colors.two, 200); // 200ms = 1s transition
        }
        if (model.color_one_meshes.includes(child.name)) {
          animateColorTransition(child, colors.one, 200); // 1s transition
        }
        if (model.button_mesh.includes(child.name)) {
          animateColorTransition(child, colors.buttons, 200); // 1s transition
        }
      }
      child.castShadow = true;
    });

    // Update previous colors reference
    previousColors.current = { ...colors };
  }, [colors, scene, model]);

  return null;
};

export default TShirtColorController;
