//IMPORTING IMAGES
function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => {
        images[item.replace("./", "")] = r(item);
    });
    return images;
}
const model = importAll(require.context("./model", true, /\.(glb|jpe?g|png)$/));

export const models = [
    {
        name: "Main Design",
        path: model["main-model/main-model.glb"],
        picture_path: model["main-model/design.jpeg"],
        front_meshes: [
            "Front_Bottom",
            "Front_Top",
        ],
        back_meshes: [
            "Back_Bottom",
            "Back_Top"
        ],
        right_meshes: [
            "Left_Cuf",
            "Left_Sleeve",
        ],
        left_meshes: [
            "Right_Cuf",
            "Right_Sleeve"
        ],
        color_one_meshes: [
            "Back_Bottom",
            "Back_Top",
            "Collar",
            "Front_Bottom",
            "Front_Top",
            "Hem",
            "Left_Cuf",
            "Left_Sleeve",
            "Placket",
            "Right_Cuf",
            "Right_Sleeve"
        ],
        color_two_meshes: [
        ],
        button_mesh: "Buttons"
    },
    {
        name: "Design #1",
        path: model["model-1/model-1.glb"],
        picture_path: model["model-1/design.jpeg"],
        front_meshes: [
            "Front_Bottom",
            "Front_Top",
        ],
        back_meshes: [
            "Back_Bottom",
            "Back_Top"
        ],
        right_meshes: [
            "Left_Cuf",
            "Left_Sleeve",
        ],
        left_meshes: [
            "Right_Cuf",
            "Right_Sleeve"
        ],
        color_one_meshes: [
            "Back_Top",
            "Front_Top",
            "Left_Sleeve",
            "Right_Sleeve",
            "Placket",
        ],
        color_two_meshes: [
            "Collar",
            "Hem",
            "Left_Cuf",
            "Right_Cuf",
            "Back_Bottom",
            "Front_Bottom",
        ],
        button_mesh: "Buttons"
    },
    {
        name: "Design #2",
        path: model["model-2/model-2.glb"],
        picture_path: model["model-2/design.jpeg"],
        front_meshes: [
            "Front_C2",
            "Front_C1",
            "FrontHem_C1",
            "FrontHem_C2",
        ],
        back_meshes: [
            "Back_C2003",
            "Back_C1",
            "BackHem_C1",
            "BackHem_C2"
        ],
        right_meshes: [
            "Left_Sleeve001",
            "Left_Cuf001"
        ],
        left_meshes: [
            "Right_Sleeve001",
            "Right_Cuf001"
        ],
        color_one_meshes: [
            "Front_C2",
            "FrontHem_C1",
            "Back_C2003",
            "BackHem_C1",
            "Left_Sleeve001",
            "Left_Cuf001",
            "Right_Sleeve001",
            "Right_Cuf001",
            "Collar001",
            "Placket001"
        ],
        color_two_meshes: [
            "Front_C1",
            "FrontHem_C2",
            "Back_C1",
            "BackHem_C2",
        ],
        button_mesh: "Buttons001"
    },
    {
        name: "Design #3",
        path: model["model-3/model-3.glb"],
        picture_path: model["model-3/design.jpeg"],
        front_meshes: [
            "Front_C2001",
            "Front_C1001",
            "Front_HemC2001",
            "Front_HemC1001",
        ],
        back_meshes: [
            "Back_C1001",
            "Back_C2002",
            "Back_HemC1001",
            "Back_HemC2001"
        ],
        right_meshes: [
            "Left_Sleeve002",
            "Left_Cuf002"
        ],
        left_meshes: [
            "Right_Sleeve002",
            "Right_Cuf002"
        ],
        color_one_meshes: [
            "Placket002",
            "Front_C2001",
            "Front_HemC2001",
            "Collar002",
            "Back_C2002",
            "Back_HemC2001",
            "Right_Sleeve002",
            "Right_Cuf002",
        ],
        color_two_meshes: [
            "Front_C1001",
            "Front_HemC1001",
            "Back_C1001",
            "Back_HemC1001",
            "Left_Sleeve002",
            "Left_Cuf002",
        ],
        button_mesh: "Buttons002"
    },
    {
        name: "Design #4",
        path: model["model-4/model-4.glb"],
        picture_path: model["model-4/design.jpeg"],
        front_meshes: [
            "Front_C1002",
            "Front_C2002",
            "Front_Hem"
        ],
        back_meshes: [
            "Back_C1002",
            "Back_C2001",
            "Back_Hem"
        ],
        right_meshes: [
            "Left_Cuf003",
            "Left_Sleeve003",
        ],
        left_meshes: [
            "Right_Cuf003",
            "Right_Sleeve003"
        ],
        color_one_meshes: [
            "Back_C1002",
            "Back_Hem",
            "Collar003",
            "Front_C1002",
            "Front_Hem",
            "Left_Cuf003",
            "Placket003",
            "Right_Cuf003"
        ],
        color_two_meshes: [
            "Back_C2001",
            "Hem",
            "Front_C2002",
            "Left_Sleeve003",
            "Right_Sleeve003",
        ],
        button_mesh: "Buttons003"
    },
    {
        name: "Design #5",
        path: model["model-5/model-5.glb"],
        picture_path: model["model-5/design.jpeg"],
        front_meshes: [
            "Front_C2003",
            "Front_C1003",
            "Front_HemC1",
            "Front_HemC2",
        ],
        back_meshes: [
            "Back_C2",
            "Back_C1003",
            "Back_HemC1",
            "Back_HemC2"
        ],
        right_meshes: [
            "Left_Sleeve004",
            "Left_Cuf004"
        ],
        left_meshes: [
            "Right_Sleeve004",
            "Right_Cuf004"
        ],
        color_one_meshes: [
            "Front_C2003",
            "Front_HemC1",
            "Front_C2003",
            "Placket004",
            "Collar004",
            "Left_Sleeve004",
            "Left_Cuf004",
            "Right_Sleeve004",
            "Right_Cuf004",
            "Back_C2",
            "Back_HemC1",
        ],
        color_two_meshes: [
            "Back_C1003",
            "Front_C1003",
            "Front_HemC2",
            "Back_HemC2",
        ],
        button_mesh: "Buttons004"
    },
]