import React, {Suspense, useEffect, useRef, useState} from "react";
import {Canvas, useThree} from "@react-three/fiber";
import {
    AccumulativeShadows,
    Html,
    OrbitControls,
    RandomizedLight,
    useProgress,
} from "@react-three/drei";
import Lights from "./Lights";
import Model from "./Model";
import Controls from "./controls/Controls";
import Ground from "./Ground";
import {models} from "./utils/models";
import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {ElementProvider, useElementContext} from "../../context/ElementContext";
import jsPDF from "jspdf";
import PdfSvg from "./utils/pdf";
import {keyframes, styled} from "styled-components";
import logo from "../../assets/logo.png";
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Create a styled div for the loader
const LoaderDiv = styled.div`
    border: 4px solid #f3f3f3; /* Light gray */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: ${spin} 1s linear infinite;
`;
function Loader() {
    const {progress} = useProgress();
    return (
        <Html
            className="bg-black text-center flex flex-col w-[100%] h-[100%] items-center justify-center absolute transition-all transition"
            center>
            <div className=" text-white flex flex-col items-center justify-center">
                {/* <LoaderDiv /> */}
                <img src={logo} width={300} height={600} alt="logo" />
                <h3>{Math.round(progress)}%</h3>
            </div>
        </Html>
    );
}
function CaptureScreenshots({
    captureAndGeneratePDF,
    onCaptureComplete,
    model,
    index,
}) {
    const {gl, camera, scene} = useThree();
    const {colors, setEditFieldsToFalse} = useElementContext();

    const captureSide = (side) => {
        setEditFieldsToFalse();
        switch (side) {
            case "front":
                camera.position.set(0, 0, 2);
                break;
            case "back":
                camera.position.set(0, 0, -2);
                break;
            case "left":
                camera.position.set(-2, 0, 0);
                break;
            case "right":
                camera.position.set(2, 0, 0);
                break;
            default:
                return;
        }
        camera.lookAt(0, 0, 0);
        gl.render(scene, camera);
        return gl.domElement.toDataURL("image/png");
    };

    useEffect(() => {
        if (captureAndGeneratePDF) {
            const frontImage = captureSide("front");
            const backImage = captureSide("back");
            const leftImage = captureSide("left");
            const rightImage = captureSide("right");

            // Generate PDF after capturing the images
            const doc = new jsPDF();
            // Function to add a watermark on each page
            const addWatermark = () => {
                const pageWidth = doc.internal.pageSize.getWidth();
                const pageHeight = doc.internal.pageSize.getHeight();
                const watermarkWidth = 200;
                const watermarkHeight = 100;

                doc.setGState(new doc.GState({opacity: 0.1})); // Set opacity to 10%
                doc.addImage(
                    logo,
                    "PNG",
                    pageWidth / 2 - watermarkWidth / 2,
                    pageHeight / 2 - watermarkHeight / 2,
                    watermarkWidth,
                    watermarkHeight
                ); // Center the watermark
                doc.setGState(new doc.GState({opacity: 1})); // Reset opacity
            };
            const addLogoToTopRight = () => {
                const pageWidth = doc.internal.pageSize.getWidth();
                const logoWidth = 40; // Width of the logo
                const logoHeight = 30; // Height of the logo
                const margin = 10; // Space from the edge of the page

                doc.addImage(
                    logo,
                    "PNG",
                    pageWidth - logoWidth - margin,
                    margin,
                    logoWidth,
                    logoHeight
                ); // Position logo in top-right
            };
            doc.text("T-Shirt Model ", 10, 10);
            doc.text(`Design: ${model.name}`, 10, 30);
            // Function to add color with circle
            const circleRadius = 5; // Set the radius of the circle
            const circleXOffset = 100; // X offset for the circle position
            const addColorTextWithCircle = (
                colorName,
                colorValue,
                yPosition
            ) => {
                doc.setFillColor(colorValue); // Set the fill color
                doc.circle(circleXOffset, yPosition, circleRadius, "F"); // Draw the colored circle
                doc.text(colorName, 10, yPosition); // Add color name text
            };
            addColorTextWithCircle(`Color 1: ${colors.one}`, colors.one, 40); // Adjusted Y-coordinate
            if (index !== 0) {
                addColorTextWithCircle(
                    `Color 2: ${colors.two}`,
                    colors.two,
                    50
                ); // Adjusted Y-coordinate
            }
            addColorTextWithCircle(
                `Button Color: ${colors.buttons}`,
                colors.buttons,
                60
            ); // Adjusted Y-coordinate
            addWatermark();
            addLogoToTopRight();

            doc.addPage();
            doc.text("T-Shirt Model - Front", 10, 10);
            doc.addImage(frontImage, "PNG", 10, 20, 180, 180);
            addWatermark();
            addLogoToTopRight();

            doc.addPage();
            doc.text("T-Shirt Model - Back", 10, 10);
            doc.addImage(backImage, "PNG", 10, 20, 180, 180);
            addWatermark();
            addLogoToTopRight();

            doc.addPage();
            doc.text("T-Shirt Model - Left", 10, 10);
            doc.addImage(leftImage, "PNG", 10, 20, 180, 180);
            addWatermark();
            addLogoToTopRight();

            doc.addPage();
            doc.text("T-Shirt Model - Right", 10, 10);
            doc.addImage(rightImage, "PNG", 10, 20, 180, 180);
            addWatermark();
            addLogoToTopRight();

            doc.save("tshirt-model.pdf");

            // Notify parent that the process is complete
            onCaptureComplete();
        }
    }, [captureAndGeneratePDF]);

    return null; // This component handles logic only
}
const Customize3d = () => {
    const [side, setSide] = useState("front");
    const [captureAndGeneratePDF, setCaptureAndGeneratePDF] = useState(false);

    // Single button to capture and save as PDF
    const handleCaptureAndSavePDF = () => {
        setCaptureAndGeneratePDF(true);
    };

    const handleCaptureComplete = () => {
        // Reset captureAndGeneratePDF to false after capturing is complete
        setCaptureAndGeneratePDF(false);
    };
    return (
        <div className="overflow-hidden border border-gray-200 p-8 w-full rounded-md flex flex-col gap-4 bg-white shadow-md">
            {/* <h1 className="text-2xl font-semibold">Customize your Uniform</h1> */}
            <Tabs>
                <TabList className="flex justify-center overflow-scroll">
                    <div className="flex flex-row gap-0 justify-center items-center ">
                        {models.map((model, index) => (
                            <Tab key={index}>
                                <div className="flex flex-col items-center justify-center">
                                    <img
                                        src={model.picture_path}
                                        width={50}
                                        className="rounded-md border border-white"
                                    />
                                    <p className="lead sm:text-xs md:text-lg" key={index}>{model.name}</p>
                                </div>
                            </Tab>
                        ))}
                    </div>
                </TabList>

                {models.map((model, index) => (
                    <TabPanel key={index}>
                        <ElementProvider>
                            <div className="flex flex-col justify-center lg:flex-row w-full gap-10">
                                <Canvas
                                    shadows
                                    className="canvas border shadow-md rounded-md flex-1 w-full h-[700px]"
                                    style={{
                                        backgroundColor: "gray",
                                        width: "100%",
                                        minHeight: "100%",
                                    }}>
                                    <Suspense fallback={<Loader />}>
                                        <Lights />
                                        <Model side={side} model={model} />
                                        <OrbitControls
                                            minPolarAngle={Math.PI / 2 - 0.2}
                                            maxPolarAngle={Math.PI / 2}
                                            makeDefault
                                        />
                                        <Lights />
                                        <Ground />
                                        <CaptureScreenshots
                                            captureAndGeneratePDF={
                                                captureAndGeneratePDF
                                            }
                                            onCaptureComplete={
                                                handleCaptureComplete
                                            }
                                            model={model}
                                            index={index}
                                        />
                                    </Suspense>
                                </Canvas>
                                <Controls
                                    index={index}
                                    side={side}
                                    setSide={setSide}
                                />
                            </div>
                            <div>
                                <p className="text-yellow-500 text-center">
                                    Make Sure To Close all Edit Indicators
                                    before saving pdf ( for Better images )
                                </p>
                                <button
                                    onClick={handleCaptureAndSavePDF}
                                    className="p-4 w-[100%] bg-red-800 text-white flex items-center justify-center gap-1">
                                    <span>Save PDF</span>
                                    <PdfSvg />
                                </button>
                            </div>
                        </ElementProvider>
                    </TabPanel>
                ))}
            </Tabs>
        </div>
    );
};

export default Customize3d;
