import React from "react";
import {useGLTF} from "@react-three/drei";
import * as THREE from "three";
import CameraController from "./CameraController";
import DecalMeshGroup from "./DecalMeshGroup";
import TShirtColorController from "./TShirtColorController";
import { useElementContext } from "../../context/ElementContext";


const Model = ({ model, side }) => {
    const {scene, cameras} = useGLTF(model.path);
    console.log(cameras)

    const {
        elements,
        addElement,
        updateElement,
        removeElement,
    } = useElementContext();
    console.log(useElementContext())

    return (
        <>
            <primitive object={scene} />
            <TShirtColorController model={model} scene={scene} />
            <DecalMeshGroup
                scene={scene}
                elements={elements.front}
                addElement={addElement}
                removeElement={removeElement}
                updateElement={updateElement}
                meshNames={model.front_meshes}
                orientation={new THREE.Euler(0, 0, 0)}
                side="front"
            />
            <DecalMeshGroup
                scene={scene}
                elements={elements.back}
                addElement={addElement}
                removeElement={removeElement}
                updateElement={updateElement}
                meshNames={model.back_meshes}
                orientation={new THREE.Euler(0, Math.PI, 0)}
                side="back"
            />
            <DecalMeshGroup
                scene={scene}
                elements={elements.right}
                addElement={addElement}
                removeElement={removeElement}
                updateElement={updateElement}
                meshNames={model.right_meshes}
                orientation={new THREE.Euler(0, Math.PI / 2, 0)}
                side="right"
                yOffset={0.2}
            />
            <DecalMeshGroup
                scene={scene}
                elements={elements.left}
                addElement={addElement}
                removeElement={removeElement}
                updateElement={updateElement}
                meshNames={model.left_meshes}
                orientation={new THREE.Euler(0, -Math.PI / 2, 0)}
                side="left"
                yOffset={0.2}
            />
            <CameraController side={side} cameras={cameras} />
        </>
    );
};

export default Model;
