import React, {useEffect, useRef, useState} from "react";
import SidewayChoice from "./SidewayChoice";
import {styled} from "styled-components";
import BackControls from "./back/BackControls";
import LeftControls from "./left/LeftControls";
import RightControls from "./right/RightControls";
import FrontControls from "./front/FrontControls";
import {useElementContext} from "../../../context/ElementContext";
import {availableColors} from "../utils/colors";

const Input = styled.input`
    border: 1px solid lightgray;
    border-radius: 10px;
`;

const Controls = ({index, side, setSide}) => {
    const {colors, updateColorOne, updateColorTwo, updateButtonsColor} = useElementContext(side);
    useEffect(() => {
        console.log(colors);
    }, [colors]);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);

    const dropdownRef1 = useRef(null);
    const dropdownRef2 = useRef(null);
    const dropdownRef3 = useRef(null);

    // Close dropdowns when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef1.current &&
                !dropdownRef1.current.contains(event.target)
            ) {
                setIsOpen1(false);
            }
            if (
                dropdownRef2.current &&
                !dropdownRef2.current.contains(event.target)
            ) {
                setIsOpen2(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef1, dropdownRef2]);
    return (
        <div className="flex flex-col gap-4 flex-1">
            {/* UPDATE COLORS */}
            <div className="flex flex-col">
                {/* TSHIRT COLOR CONTROL WHICH IS GENERAL NO NEED TO DIVIDE INTO PARTS */}
                <h1 className="text-2xl font-semibold">T-Shirt Colors</h1>
                <div className="flex gap-3">
                    <div>
                        <label className="flex gap-3 items-center">
                            <span className="text-nowrap">
                                Color {index !== 0 && "1"}
                            </span>
                            <button
                                id="color1-btn"
                                className="w-10 h-10 rounded-full border border-gray-300"
                                style={{backgroundColor: colors.one || "white"}}
                                onClick={() => setIsOpen1(!isOpen1)}
                            />
                            {isOpen1 && (
                                <div
                                    id="color1"
                                    ref={dropdownRef1}
                                    className="absolute mt-2 p-2 bg-white rounded-lg shadow-lg grid grid-cols-6 gap-1">
                                    {availableColors.map((color, index) => (
                                        <button
                                            key={index}
                                            className="w-8 h-8 rounded-full border-2 border-white hover:border-gray-400"
                                            style={{backgroundColor: color}}
                                            onClick={() => {
                                                updateColorOne(color);
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                        </label>
                    </div>
                    {index !== 0 && (
                        <div>
                            <label className="flex gap-3 items-center">
                                <span className="text-nowrap">Color 2</span>
                                <button
                                    id="color1-btn"
                                    className="w-10 h-10 rounded-full border border-gray-300"
                                    style={{
                                        backgroundColor: colors.two || "black",
                                    }}
                                    onClick={() => setIsOpen2(!isOpen2)}
                                />
                                {isOpen2 && (
                                    <div
                                        id="color1"
                                        ref={dropdownRef2}
                                        className="absolute mt-2 p-2 bg-white rounded-lg shadow-lg grid grid-cols-6 gap-1">
                                        {availableColors.map((color, index) => (
                                            <button
                                                key={index}
                                                className="w-8 h-8 rounded-full border-2 border-white hover:border-gray-400"
                                                style={{backgroundColor: color}}
                                                onClick={() => {
                                                    updateColorTwo(color);
                                                }}
                                            />
                                        ))}
                                    </div>
                                )}
                            </label>
                        </div>
                    )}
                    <div>
                        <label className="flex gap-3 items-center">
                            <span className="text-nowrap">
                                Buttons Color
                            </span>
                            <button
                                id="color1-btn"
                                className="w-10 h-10 rounded-full border border-gray-300"
                                style={{backgroundColor: colors.buttons_color || "white"}}
                                onClick={() => setIsOpen3(!isOpen3)}
                            />
                            {isOpen3 && (
                                <div
                                    id="color1"
                                    ref={dropdownRef1}
                                    className="absolute mt-2 p-2 bg-white rounded-lg shadow-lg grid grid-cols-6 gap-1">
                                    {availableColors.map((color, index) => (
                                        <button
                                            key={index}
                                            className="w-8 h-8 rounded-full border-2 border-white hover:border-gray-400"
                                            style={{backgroundColor: color}}
                                            onClick={() => {
                                                updateButtonsColor(color);
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                        </label>
                    </div>
                </div>
            </div>

            {/* BASED ON THE SIDE CHOICE THE CONTROLS SHOW */}
            <SidewayChoice side={side} setSide={setSide}>
                {side === "front" ? (
                    <FrontControls
                        elements
                        addElement
                        updateElement
                        removeElement
                    />
                ) : side === "back" ? (
                    <BackControls
                        elements
                        addElement
                        updateElement
                        removeElement
                    />
                ) : side === "right" ? (
                    <RightControls
                        elements
                        addElement
                        updateElement
                        removeElement
                    />
                ) : (
                    side === "left" && (
                        <LeftControls
                            elements
                            addElement
                            updateElement
                            removeElement
                        />
                    )
                )}
            </SidewayChoice>
        </div>
    );
};

export default Controls;
