import React, { createContext, useContext, useReducer, useState } from 'react';
import { elementReducer, initialState } from './elementReducer';

// Create a single context for all elements
const ElementContext = createContext();

export const useElementContext = (side) => {
    const context = useContext(ElementContext);
    if (!context) {
        throw new Error("useElementContext must be used within an ElementProvider");
    }

    return {
        elements: side ? context.state.elements[side] : context.state.elements,
        addElement: (element) => context.dispatch({ type: 'ADD_ELEMENT', side, payload: element }),
        updateElement: (elementId, newProps) => context.dispatch({ type: 'UPDATE_ELEMENT', side, payload: { id: elementId, props: newProps } }),
        removeElement: (elementId) => context.dispatch({ type: 'REMOVE_ELEMENT', side, payload: { id: elementId } }),

        // New method to handle reordering
        reorderElements: (sourceIndex, destinationIndex) =>
            context.dispatch({
                type: 'REORDER_ELEMENTS',
                payload: { sourceIndex, destinationIndex, side }
            }),

        colors: context.state.colors,
        updateColorOne: (newColor) => context.dispatch({ type: 'UPDATE_COLOR_ONE', payload: { newColor } }),
        updateColorTwo: (newColor) => context.dispatch({ type: 'UPDATE_COLOR_TWO', payload: { newColor } }),
        updateButtonsColor: (newColor) => context.dispatch({ type: 'UPDATE_BUTTONS_COLOR', payload: { newColor } }),
        setEditFieldsToFalse: () => context.dispatch({ type: 'SET_EDIT_FIELDS_TO_FALSE' })
    };
};

export const ElementProvider = ({ children }) => {
    const [state, dispatch] = useReducer(elementReducer, initialState);

    return (
        <ElementContext.Provider value={{ state, dispatch }}>
            {children}
        </ElementContext.Provider>
    );
};
