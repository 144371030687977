import React, {useEffect, useState} from "react";
import * as THREE from "three";
import {useElementContext} from "../../../../context/ElementContext";

const ElementControls = ({index, updateElement}) => {
    const {elements} = useElementContext("right");
    let element = elements[index];
    const [controlValues, setControlValues] = useState({
        content: element.content,
        color: element.color,
        position: new THREE.Vector3(...element.position),
        scale: new THREE.Vector3(...element.scale),
    });

    useEffect(() => {
        setControlValues({
            content: element.content,
            color: element.color,
            position: new THREE.Vector3(...element.position),
            scale: new THREE.Vector3(...element.scale),
        });
    }, [element]);
    return (
        <div className="p-4 bg-gray-100 border border-gray-200 rounded flex flex-col gap-1">
            {element.type === "text" && (
                <>
                    <label className="flex gap-3 items-center">
                        <input
                            style={{border: "1px solid lightgray"}}
                            type="text"
                            value={controlValues.content}
                            className="w-full rounded-md px-2 py-1 outline-none"
                            onChange={(e) => {
                                console.log(e);
                                updateElement(element.id, {
                                    content: e.target.value,
                                });
                            }}
                        />
                    </label>
                    <label className="flex gap-3 items-center">
                        Text Color
                        <input
                            style={{border: "1px solid lightgray"}}
                            type="color"
                            value={controlValues.color}
                            onChange={(e) =>
                                updateElement(element.id, {
                                    color: e.target.value,
                                })
                            }
                        />
                    </label>
                </>
            )}
            {/* X POSITION CONTROLS */}
            <label className="flex flex-col">
                <span>Position X</span>
                <input
                    type="range"
                    min="-0.2"
                    max="0.2"
                    step="0.001"
                    value={controlValues.position.x && controlValues.position.x}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                    onChange={(e) =>
                        updateElement(element.id, {
                            position: new THREE.Vector3(
                                parseFloat(e.target.value),
                                element.position.y,
                                element.position.z
                            ),
                        })
                    }
                />
            </label>
            {/* Y POSITION CONTROLS */}
            <label className="flex flex-col">
                <span>Position Y</span>
                <input
                    type="range"
                    min="-0.35"
                    max="0.35"
                    step="0.01"
                    value={controlValues.position.y}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                    onChange={(e) =>
                        updateElement(element.id, {
                            position: new THREE.Vector3(
                                element.position.x,
                                parseFloat(e.target.value),
                                element.position.z
                            ),
                        })
                    }
                />
            </label>
            {/* SCALE CONTROLS */}
            <label className="flex flex-col">
                <span>Scale</span>
                <input
                    type="range"
                    min={element.type === "text" ? "0.4" : "0.05"}
                    max={element.type === "text" ? "1.5" : "0.5"}
                    step="0.01"
                    value={controlValues.scale.x}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                    onChange={(e) =>
                        updateElement(element.id, {
                            scale: new THREE.Vector3(
                                parseFloat(e.target.value),
                                parseFloat(e.target.value),
                                parseFloat(e.target.value) * 10
                            ),
                        })
                    }
                />
            </label>
        </div>
    );
};

export default ElementControls;
