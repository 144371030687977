import "./App.css";
import WowWrapper from "./WowWrapper";
import "animate.css/animate.min.css";
import Navbar from "./components/Navbar/Navbar";
import CustomizePage from "./pages/customize-3d/CustomizePage";
import Uniform from "./pages/customize/uniform/Uniform";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
    return (
        <>
            <Router>
                <WowWrapper>
                    <div className="bg-slate-100">
                        <Routes>
                            <Route index element={<CustomizePage />} />
                        </Routes>
                    </div>
                </WowWrapper>
            </Router>
        </>
    );
}

export default App;
